/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'typeface-source-sans-pro'

// Set moment locale
import * as moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl')
