// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-template-js": () => import("./../src/templates/article-list-template.js" /* webpackChunkName: "component---src-templates-article-list-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-future-entofarmers-hub-js": () => import("./../src/pages/future-entofarmers-hub.js" /* webpackChunkName: "component---src-pages-future-entofarmers-hub-js" */)
}

